export const environment = {
    idp: {
        debug: true,
        microsoft: {
            authority: 'https://login.microsoftonline.com/common',
            clientid: '8bbcee02-d84a-4e70-96aa-ce3d8c690639',
            login_redirect_uri: 'https://ociso.scenic.llc',
            logout_redirect_uri: 'https://ociso.scenic.llc'
        },
        google: {
            clientid: '854096899354-8sneu7rfkdlp3eaplq6fngtk6h9ct2l4.apps.googleusercontent.com'
        }
    },
    endpoints: {
        ui: {
            login: 'https://ociso.scenic.llc',
            admin: 'https://admin.ociso.scenic.llc',
            assessor: 'https://assessor.ociso.scenic.llc',
            tenant: 'https://tenant.ociso.scenic.llc'
        },
        api: {
            identityV1: 'https://ocisop-identityapi.azurewebsites.net/v1',
            artifactV1: 'https://ocisop-artifactapi.azurewebsites.net/v1'
        }
    },
    portal: {
        debug: false,
        userInfoDialogIsModal: true,
        useSessionStorageForTokens: true,
        myInfoCacheMillis: 300000,
        minimumLettersForKeywordFilter: 2,
        defaultEventNotificationSeconds: 5,
        defaultEventLongNotificationSeconds: 10,
        roles: {
            defaultUserRoleWhenRedirectingToCreateUser: 'SystemOwner',
            minimumAssignmentsToShowFilter: 3,
        },
        security: {
            tokenClaimForRole: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role',
            tokenClaimForAssignment: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata',
            tokenClaimForEmail: 'sub',
            tokenClaimForTenant: 'http://schemas.microsoft.com/ws/2008/06/identity/claims/groupsid',
            redirectPageWhenFailedGuard: ''
        },
        tenants: {
            maxSummaryLengthForTenantRoleResponsibilities: 70,
        }
    }
};
